import gql from 'graphql-tag';

import { getApollo } from './getApollo';
import { BootNiceQuery } from './gqlTypes';

declare global {
  interface Window {
    cxone: any; // Define this so we can use window.cxone below without a type error
  }
}

type BootNiceParams = {
  isAdmin: boolean;
  isContractorPayments: boolean;
  isInImplementation: boolean;
};

const bootNiceQuery = gql`
  query BootNiceQuery {
    dashboard(id: "me") {
      id
      company {
        id
        hrProxy {
          id
          hrContact {
            id
            hrContactName
            hrContactPhone
            hrContactEmail
          }
        }
      }
      employee {
        id
        preferredOrFirstName
        last_name
      }
      niceCxoneExpertAuthtoken
    }
    roleAndCategory {
      isBroker
    }
  }
`;

const getGqlData = async () => {
  const { data } = await getApollo().query<BootNiceQuery.Query>({
    query: bootNiceQuery,
    context: { headers: { 'IS-BACKGROUND-QUERY': true } },
  });

  return data;
};

export const getMenuType = (params: BootNiceParams & { isBroker?: boolean | null }) => {
  const { isAdmin, isBroker, isContractorPayments, isInImplementation } = params;

  const role = isBroker ? 'Broker' : isAdmin ? 'Admin' : 'Employee';

  return `${isContractorPayments ? 'contractorPayments' : 'regular'}${
    isInImplementation ? 'Implementation' : 'Active'
  }${role}`;
};

// Given a value, return it if it's truthy, otherwise return a dash
const orDash = (value: string | undefined | null) => {
  return value || '-';
};

export enum CustomerProfile {
  Regular = 'regular',
  ContractorPaymentsAdmin = 'contractorPayments_admin',
  ContractorPaymentsEmployee = 'contractorPayments_employee',
}

export const getCustomerProfile = (params: { isAdmin: boolean; isContractorPayments: boolean }) => {
  const { isAdmin, isContractorPayments } = params;

  if (!isContractorPayments) {
    return CustomerProfile.Regular;
  }

  // It is Contractor Payments which means the user cannot be a broker, so we only need to consider admin or employee.
  return isAdmin ? CustomerProfile.ContractorPaymentsAdmin : CustomerProfile.ContractorPaymentsEmployee;
};

export default async (params: BootNiceParams) => {
  if (window.cxone) {
    // NICE script is already loaded.
    return;
  }

  const {
    dashboard: { company, employee, niceCxoneExpertAuthtoken },
    roleAndCategory,
  } = await getGqlData();

  const isBroker = roleAndCategory?.isBroker;
  const menuType = getMenuType({ ...params, isBroker });

  const { hrContactName, hrContactPhone, hrContactEmail } = company?.hrProxy?.hrContact || {};
  const { preferredOrFirstName, last_name } = employee || {};
  const userFullName = `${preferredOrFirstName} ${last_name}`;
  const customerProfile = getCustomerProfile({
    isAdmin: params.isAdmin,
    isContractorPayments: params.isContractorPayments,
  });

  const scriptCode = `
    (function(n,u){
        window.CXoneDfo=n,
        window[n]=window[n]||function(){(window[n].q=window[n].q||[]).push(arguments)},window[n].u=u,
        e=document.createElement("script"),e.type="module",e.src=u+"?"+Math.round(Date.now()/1e3/3600),
        document.head.appendChild(e)
        })('cxone','https://web-modules-de-na1.niceincontact.com/loader/1/loader.js');

        cxone('init', '4419');
        cxone('guide', 'init');

        cxone('chat','setCaseCustomField', 'menutype', '${menuType}');
        /*
          Why using field 'hrcontactname' instead of 'companyhrcontactname' (to be consistent with others)?
          - We used to have a field companyhrcontactname, but that field got messed up in NICE,
            so we decided to switch to the new field hrcontactname instead of waiting for the old field to be fixed
            on the NICE side.

          Why using orDash to return '-' instead of empty string?
          - The cxone API does not allow empty string to be set as custom field value.
          - Also tried skipping a line if the corresponding value is not available. That does not work either because it
            shows the raw field name directly in the UI. For example, "{contact.custom_fields.companyhrcontactphone}"
            will be showed.
        */
        cxone('chat','setCaseCustomField', 'hrcontactname', '${orDash(hrContactName)}');
        cxone('chat','setCaseCustomField', 'companyhrcontactphone', '${orDash(hrContactPhone)}');
        cxone('chat','setCaseCustomField', 'companyhrcontactemail', '${orDash(hrContactEmail)}');
        cxone('chat','setCaseCustomField', 'userfullname', '${userFullName}');
        cxone('chat','setCaseCustomField', 'expertauthtoken', '${niceCxoneExpertAuthtoken}');

        // Let the bot start the conversation
        cxone('chat','sendFirstMessageAutomatically', '/begin_conversation');

        // Skip asking user to enter their name.
        cxone('chat', 'autoStartSession');

        // This will trigger the different Guide templates where the different knowledge configurations are setup.
        cxone('analytics','setVisitorVariable', 'customerprofile', '${customerProfile}');
    `;

  const script = document.createElement('script');
  script.innerHTML = scriptCode;
  document.head.appendChild(script);
};
